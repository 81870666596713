@import "src/styles/breakpoints";
@import "src/styles/colors";

.page-orgchart {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  font-size: 10px;

  @include media-breakpoint-up(xl) {
    padding: 0;
  }

  .orgchart-page-title {
    padding: 16px 0;
    font-weight: 700;
    text-transform: uppercase;
  }

  .orgchart-page-container {
    min-height: 300px;
    margin-bottom: 20px;
    animation: fadein 2s;
    border: 1px solid $hawkes-blue;
    border-radius: 4px;
    background: $white;
  }
}

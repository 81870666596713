.orgchart-container {
    background-color: transparent !important;
    border: 0px;
}

.orgchart {
    background-image: none !important;
    background-color: transparent !important;
}

.orgchart .node {
    padding: 0px;
}

.orgchart .node .top-content {
    width: 100%;
    height: 25px;
    line-height: 25px;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    font-size: 12px;
    font-weight: bold;
    box-sizing: border-box;
}

.orgchart .node .top-content.division {
    background-color: #0083ff !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0px 5px;
}

.orgchart .node .node-image {
    width: 100%;
}

.orgchart .node .node-image > img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    margin-bottom: 5px;
    border: solid 2px #fff;
    object-fit: cover;
    margin: 5px;
}

.orgchart .node .title .parentNodeSymbol {
    display: none;
}

.orgchart .node .title {
    background-color: transparent;
    width: 100%;
}

.orgchart .node .content {
    background-color: transparent;
    border: 0px;
    color: #fff;
    width: 100%;    
    min-height: 30px;
}

.node {
    width: 160px;
}

.node .node-image {
    min-height: 10px;
    max-height: 90px;
    box-sizing: border-box;
}

.node .title {
    border-radius: 0px !important;
}

.node .content {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    padding-bottom: 10px;
    height: auto !important;
}

.node.ceo div {
    background-color: #5f2acf !important;
    color: #fff;
}

.node.team-lead div {
    background-color: #8657ed !important;
    color: #fff;
}

.node.team-member div {
    background-color: #a780f6 !important;
    color: #fff;
}

.node.focused,
.node:hover {
    background-color: transparent !important;
}

.node div {
    transition: background-color 0.2s ease-in-out;
}

.node.focused div {
    background-color: orange !important;
}

.hierarchy {
    /*background-color: #fff;*/
}

/*.node {
    text-align: center;
    padding: 0px;
    background-color: #ffffff;
    width: 160px;
    border-radius: 10px;
    border: solid 2px #f0f2f5 !important;
    padding: 10px !important;
}

.node.ceo, .node.ceo.focused, .node.ceo:hover {
    background-color: #5f2acf !important;
    color: #fff;
}

.node.team-lead, .node.team-lead.focused, .node.team-lead:hover {
    background-color: #8657ed !important;
    color: #fff;
}

.node.team-member, .node.team-member.focused, .node.team-member:hover {
    background-color: #a780f6 !important;
    color: #fff;
}*/

/*

.orgchart .node .node-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 5px;
    border: solid 2px #fff;
    object-fit: cover;
}

.node-details {
    font-size: 14px;
}

.node-title {
    font-size: 12px;
    padding: 0px;
}*/

.orgchart .node .edge { display: none; }

#org-chart-container {
    position: relative;
    border: 0px;
    margin: 0.5rem;
    overflow: auto;
    text-align: center;
}

.orgchart .hierarchy::before {
    border-color: #aaa !important;
}

.orgchart .hierarchy:not(.couple) > .node:has(+ .nodes)::after {
    background-color: #aaa !important;
}

.orgchart > ul > li > ul li > .node:not(.outsider)::before {
    background-color: #aaa !important;
}

.orgchart .nodes.vertical .hierarchy::after {
    border-color: #aaa !important;
}
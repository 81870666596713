@import "src/styles/colors";
@import "src/styles/breakpoints";

.wiki-ai__conversation-container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  height: 80vh;
  margin: 20px;
  padding: 20px;
  gap: 1rem;
  border-radius: 5px;
  background-color: $white;

  .wiki-ai__company-logo {
    position: absolute;
    z-index: 0;
    top: 45%;
    left: 50%;
    height: 75px;
    transform: translate(-50%, -50%);
    opacity: .2;
  }

  .wiki-ai__messages-container {
    max-height: 80vh;
    overflow-y: auto;

    .wiki-ai__message {
      position: relative;
      z-index: 1;
      width: fit-content;
      max-width: 80%;
      margin: 10px 0;
      padding: 8px;
      border-radius: 5px;
      white-space: break-spaces;

      &.answer {
        background-color: $dodger-blue-light;
        color: $white;
      }

      &.question {
        margin-right: 0;
        margin-left: auto;
        background-color: $grey-light;
      }

      &.error {
        background-color: $radical-red;
        color: $white;
      }
    }
  }

  .wiki-ai__message-input {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .wiki-ai__send-message-button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      background-color: $dodger-blue-light;

      svg {
        fill: $white
      }
    }
  }
}
